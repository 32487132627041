.heading {
	@apply pb-[50px] text-center mx-auto px-[4px];
	@apply msm:pb-[70px];
	@apply md:pb-[80px];
}

.heading .subtitle {
	@apply leading-normal;
}

.heading .title {
	@apply font-jakarta tracking-wide font-bold;
}

.headingPrimary .title {
	@apply tracking-wide text-[32px] leading-tight;
	@apply msm:text-[40px];
	@apply md:text-[44px];
	@apply lg:text-[48px];
}

.headingPrimary .subtitle {
	@apply text-primary mb-[6px] text-[16px];
	@apply xsm:text-[18px];
	@apply msm:text-[20px];
	@apply lg:text-[22px];
}

.headingRegular {
	@apply flex flex-col max-w-[600px];
}

.headingRegular .title {
	@apply mb-[15px] text-[34px];

	@apply xsm:text-[38px];
	@apply msm:text-[50px];
	@apply md:text-[52px];
	@apply lg:text-[56px];
}

.headingRegular .subtitle {
	@apply max-w-[650px] mx-auto text-white-800;
	@apply text-[16px] leading-normal;
	@apply msm:text-[18px];
	@apply lg:text-[20px];
}
