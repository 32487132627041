.spinner {
	@apply my-4 inline-block w-12 h-12 rounded-full border-t-4 border-r-4 border-solid border-t-white-800 border-r-transparent;
	animation: rotation 1s linear infinite;
}
.spinner::after {
	content: '';
	@apply absolute left-0 top-0 w-12 h-12 rounded-full border-b-4 border-solid border-b-primary border-l-4 border-l-transparent;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
