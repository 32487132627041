.sectionWrapper {
	@apply relative py-[15px] px-[20px] bg-white;
}

.topBgImage {
	background-image: url('/vectores/featured-section-white.svg');
	background-position: 50%;
	background-repeat: 50%;
	background-size: auto;
	width: 100%;
	height: 20px;
	position: absolute;
	inset: -10px 0% auto;
}

.bottomBgImage {
	top: auto;
	bottom: -10px;
}

.contentWrapper {
	@apply py-[40px] bg-eerieBlack rounded-[20px];
	@apply msm:py-[50px];
	@apply md:py-[60px];
	@apply lg:py-[100px];
	@apply xl:py-[120px];
}

.content::after {
	clear: both;
}

.content::after,
.content::before {
	content: '';
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.content {
	@apply max-w-[1300px] px-[15px] mx-auto block;
}
