.featuresContainer {
	@apply max-w-[1300px];
}

.features {
	@apply grid gap-x-[16px] gap-y-[16px] grid-cols-1;
	@apply sm:grid-cols-2;
	@apply lg:grid-cols-3;
}

.feature {
	@apply flex flex-col items-center justify-start text-center;
}

.featureIconContainer {
	@apply z-10 flex items-center justify-center relative min-w-[80px] min-h-[80px] rounded-full bg-primary;
	@apply mb-[-42px];
}

.featureIconContainer svg {
	@apply stroke-black;
}
.featureCard {
	@apply relative overflow-hidden w-full flex flex-col items-center justify-start bg-eerieBlack-light rounded-[10px];
	@apply pt-[80px] px-[10px] pb-[32px];
	@apply ssm:pt-[80px];
	@apply lg:pt-[90px];
	@apply flex-grow h-full;
}

.featureCard img {
	@apply inline-block rotate-90;
}

.featureCard h2 {
	@apply mb-[10px] mt-[33px] capitalize text-[20px] font-jakarta tracking-[1px] leading-[140%] font-bold;
	@apply ssm:text-[24px];
	@apply md:mb-[5px];
}

.featureCard p {
	@apply mb-[10px] text-[16px]  text-white-800 font-normal leading-normal;
	@apply ssm:text-[18px];
}
