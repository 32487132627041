.contactOptionsWrapper {
	@apply ssm:px-[10px] min-w-full;
}

.optionsCard {
	@apply mt-[30px] lg:mt-[0];
}

.optionsCard h2 {
	@apply mb-[15px] text-[28px] leading-[110%] font-jakarta font-bold;
	@apply ssm:text-[32px] ssm:leading-[115%];
	@apply md:text-[36px] md:leading-[120%];
}

.optionsCard > p {
	@apply text-[16px] leading-normal font-normal text-white-800;
	@apply ssm:text-[18px];
}

.option {
	@apply mt-[24px] py-[26px] pl-[24px] flex flex-col gap-y-[15px] border-[1px] border-solid border-white-400 rounded-[10px] min-w-full text-start;
	@apply hover:bg-eerieBlack-light-hover focus:bg-eerieBlack-light-hover;
	@apply msm:flex-row;
	transition: background-color 0.3s;
}

.icon {
	@apply mr-[20px] flex items-center justify-center p-[12px] bg-primary w-[64px] h-[64px] rounded-[10px];
}

.icon svg {
	@apply stroke-eerieBlack;
}

.info {
	@apply flex flex-col justify-center;
}
.info p {
	@apply mb-[5px] leading-[120%] font-jakarta font-bold text-[18px];
}
.info span {
	@apply text-[16px] text-white-800 font-normal leading-normal;
	@apply ssm:text-[18px];
}
