.banner {
	@apply overflow-hidden;
}

.banner a {
	@apply flex bg-white py-[15px];
}

.slide {
	@apply flex items-center justify-start whitespace-nowrap;

	animation: slide 40s infinite linear;
}

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}
