.card {
	@apply flex flex-col gap-[24px] text-center;
}

.card h3 {
	@apply text-[16px] xsm:text-[18px] msm:text-[20px] lg:text-[22px];
}

.card a {
	@apply inline-block py-[20px] px-[20px] text-black rounded-full bg-primary text-[18px] font-bold min-w-full text-center;
	@apply xsm:px-[30px];
	@apply hover:bg-white focus:bg-white;
	@apply md:text-[20px];
	@apply lg:text-[22px];
	transition: background-color 0.3s;
}
