.formCard fieldset {
	@apply flex flex-col gap-[24px];
}

.formCard fieldset > div {
	@apply flex flex-col gap-[12px];
}

.formCard fieldset input {
	@apply py-[12px] px-[12px] w-full bg-white-100 rounded-[12px] text-[15px];
	@apply hover:bg-white-118 focus:bg-white-118;
	@apply ssm:text-[14px];
	@apply msm:text-[16px];
	@apply md:px-[16px] md:py-[18px];
	@apply lg:text-[18px];
	transition: color 0.3s, background-color 0.3s;
}

.formCard fieldset input::placeholder {
	@apply text-white-400;
}

.formCard fieldset label {
	@apply text-[16px] xsm:text-[18px] msm:text-[20px];
}

.formCard fieldset p {
	@apply text-[12px] xsm:text-[14px] msm:text-[16px];
}
.formContent .btn,
.formContent a {
	@apply inline-block px-[20px] py-[16px] text-black rounded-full bg-primary text-[18px] font-bold min-w-full text-center;
	@apply hover:bg-white focus:bg-white;
	@apply xsm:px-[30px];
	@apply msm:text-[20px] msm:py-[20px];
	@apply md:text-[20px];
	@apply lg:text-[22px];
	transition: background-color 0.3s, color 0.3s;
}

.formFooter {
	@apply flex flex-col gap-[12px] pt-[32px] text-center text-[16px] xsm:text-[18px] msm:text-[20px];
}

.formFooter div {
	@apply flex gap-[4px] justify-center;
}

.formFooter p {
	@apply text-white-800;
}

.formFooter p > a {
	@apply text-white;
}

.formCard fieldset:disabled input,
.formCard fieldset:disabled textarea {
	@apply text-white-800 bg-white-50;
}

.formCard fieldset:disabled button {
	@apply bg-white-100 text-white-400;
	@apply hover:bg-white-118;
}
