.bannerItem {
	@apply flex items-center min-w-max select-none;
}

.bannerSectionImage {
	@apply w-[38px] h-[38px] inline-block mx-[15px];
	@apply md:mx-[24px];
	@apply lg:mx-[54px];
}

.bannerSectionSpan {
	@apply my-[10px] text-[22px] text-eerieBlack tracking-[2px] capitalize whitespace-nowrap font-semibold font-jakarta leading-[120%];
	@apply ssm:text-[26px];
	@apply lg:text-[30px];
}
