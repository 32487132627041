.sectionClients {
	@apply pb-[50px] text-center px-6;
	@apply lg:px-5;
}

/* clients.module.css */

/* ... tus estilos existentes ... */

/* Estilos para el Skeleton */

.clientsWrapper {
	@apply max-w-[1300px] mx-auto grid grid-cols-2 gap-[20px];
	@apply ssm:gap-6;
	@apply msm:grid-cols-3;
	@apply md:grid-cols-4 md:gap-6;
	@apply lg:gap-10;
}
.mockupPhone {
	position: relative;
	width: 100%;
	aspect-ratio: 9/16;
}

.skeleton {
	background-color: #2e2e2e; /* Color base del skeleton */
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	padding: 20px;
}

.skeleton::before {
	content: '';
	position: absolute;
	top: 0;
	left: -150px;
	height: 100%;
	width: 150px;
	background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
	animation: loading 1.5s infinite;
}

@keyframes loading {
	0% {
		left: -150px;
	}
	50% {
		left: 100%;
	}
	100% {
		left: 100%;
	}
}

.skeletonText {
	width: 60%;
	height: 16px;
	background-color: #3e3e3e;
	margin: 10px auto;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
}

.skeletonSlide {
	width: 100%;
	height: 100%;
	background-color: #3e3e3e;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

.mockupLinkImageContainer {
	background-color: #3e3e3e;
	border-radius: 50%;
	width: 100%;
	height: 100%;
}
