.clientsWrapper {
	@apply max-w-[1300px] mx-auto grid grid-cols-2 gap-[20px];
	@apply ssm:gap-6;
	@apply msm:grid-cols-3;
	@apply md:grid-cols-4 md:gap-6;
	@apply lg:gap-10;
}

.clientsMockupWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #1e1e1e;
	border-radius: 10px;
}

.clientsMockupWrapper p {
	padding: 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
}

.mockupPhone {
	@apply relative w-full aspect-[9/16] bg-eerieBlack-light;
}

.slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.slides {
	display: flex;
	height: 100%;
	animation: slideAnimation infinite;
}

.slide {
	aspect-ratio: 9/16;
	height: 100%;
	width: auto;
	position: relative;
}

.slide img {
	object-fit: cover;
	aspect-ratio: 9/16;
}

@keyframes slideAnimation {
	0% {
		transform: translateX(0%);
	}
	20% {
		transform: translateX(0%);
	}
	25% {
		transform: translateX(-25%);
	}
	45% {
		transform: translateX(-25%);
	}
	50% {
		transform: translateX(-50%);
	}
	70% {
		transform: translateX(-50%);
	}
	75% {
		transform: translateX(-75%);
	}
	95% {
		transform: translateX(-75%);
	}
	100% {
		transform: translateX(0%);
	}
}

.clientsMockupWrapper p {
	@apply p-[10px] text-[12px] font-semibold text-center;
	@apply xsm:p-[16px] xsm:text-[14px];
	@apply ssm:text-[14px];
	@apply sm:text-[16px];
}

.mockupLink {
	@apply h-12 w-12  z-10 p-2 absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 rounded-full bg-primary;
	@apply xsm:h-16 xsm:w-16;
	@apply md:h-[72px] md:w-[72px];
	@apply lg:h-[80px] lg:w-[80px];
	backdrop-filter: blur(2px);
}

.mockupLinkImageContainer {
	position: relative;
	width: 100%;
	height: 100%;
}

.mockupSlidersFallback {
	@apply col-span-4;
}
